(function() {
    'use strict';

    angular.module('aerosApp')
        .factory("instructions", InstructionsFactory);

    InstructionsFactory.$inject = ['routesConstant'];

    function InstructionsFactory(routesConstant) {

        var pages = {};

        (function initCustomerPages(pages, cm) {
            pages[cm.PROJECTS.stateName] = {
                title: "Projects",
                type: "Projects Dashboard",
                instructions: 'Available Actions - Project Dashboard',
                actions: [
                    {action: "Project Details", icon: "signal", instructions: "View details for each project"},
                    {action: "Merge Projects", icon: "code-fork", instructions: "Merge two projects"},
                    {action: "Create Project", icon: "plus", instructions: "Create a new project"},
                    {action: "Edit Project", icon: "pencil", instructions: "Edit a project (name or comment)"},
                    {action: "Project Merging", icon: "spinner", instructions: "Project is merging in background (click to cancel)"},
                    {action: "Info", icon: "info", instructions: "View additional information/Details"},
                    {action: "View Results", icon: "bar-chart", instructions: "View Fibergroup Results"},
                    {action: "Hide Project", icon: "ban", instructions: "Hide an existing project. (for filter view)"},
                    {action: "Unhide Project", icon: "undo", instructions: "Reload a disabled project"},
                    {action: "Copy Project", icon: "files-o", instructions: "Create a copy of an existing project."},
                    {action: "Delete Project", icon: "trash", instructions: "Delete a project (cannot be undone)"}
                ]
            };

            pages[cm.PROJECTS_PROJECT_FIBERGROUPS.stateName] = {
                type: "Fiber Group Toolkit",
                instructions: 'Available Actions - Fibergroups',
                actions: [
                    {action: "Create New Fibergroup", icon: "plus", instructions: "Create a new fibergroup / Create a new test"},
                    {action: "Upload", icon: "upload", instructions: "Attach a file and associate with project or fibergroup"},
                    {action: "Info", icon: "info", instructions: "View additional information/Details"},
                    {action: "Select FGs", icon: "check-square-o", instructions: "Selected none/some/all fibergroups"},
                    {action: "Notification", icon: "bell-o", instructions: "Manually trigger notification to remote devices"},
                    {action: "Progress Details", icon: "signal", instructions: "View details of progress of each fibergroup"},
                    {action: "Delete Fibergroups", icon: "trash", instructions: "Delete selected fibergroup(s) and all associated results (cannot be undone)"},
                    {action: "Copy Fibergroups", icon: "files-o", instructions: "Create copies of selected fibergroups"},
                    {action: "Remove Test Setup",icon: "times", instructions: "Remove a test setup from a fibergroup"},
                    {action: "Edit Fibergroup", icon: "pencil", instructions: "Edit fibergroup name fields"},
                    {action: "Results", icon: "bar-chart", instructions: "View results associated with a single fibergroup"}
                ]
            };

            pages[cm.PROJECTS_PROJECT_TEAM.stateName] = {
                type: "Project Team",
                instructions: 'Available Actions – Project Team',
                actions: [
                    {icon: "plus", instructions: "Assign existing users to a project role"},
                    {icon: "info", instructions: "View additional details about User Roles"},
                    {icon: "pencil", instructions: "Edit the role of an existing user"},
                    {icon: "lock", instructions: "Email user a link to reset their password"},
                    {icon: "times", instructions: "Remove an assigned user from the project (does not affect other projects)."}
                ]
            };

            pages[cm.PROJECTS_PROJECT_RESULTS.stateName] = {
                type: "Project Results",
                instructions: '',
                actions: []
                //{icon: "dot-circle-o", instructions: "Shown at right of a fibergroup row if EFI images exist (click to view)"}
                //]
            };

            pages[cm.PROJECTS_PROJECT_CONTACTS.stateName] = {
                type: "Project Contacts",
                instructions: 'Available Actions – Project Contacts',
                actions: [
                    {icon: "plus", instructions: "Create a new contact record and apply it to the project."}
                ]
            };

            pages[cm.PROJECTS_PROJECT_REPORT.stateName] = {
                type: "Project Reports",
                instructions: '',
                actions: []
            };

            pages[cm.ORGANIZATION_PROFILE.stateName] = {
                title: "Organization Profile",
                type: "Organization Profile",
                instructions: 'Available Actions – Organization Profile',
                actions: [
                    {action: "Add License", icon: "plus", instructions: "Add a new License / Add a new Rogue Unit"},
                    {action: "Info", icon: "info", instructions: "View additional information/Details"},
                    {action: "Reset Password", icon: "lock", instructions: "Email user a link to reset their password"},
                    {action: "Activation", icon: "power-off", instructions: "Email user a link to activate their account"},
                    {action: "Unassign", icon: "sign-out", instructions: "Unassign this user from the assigned license"},
                    {action: "Assign", icon: "sign-in", instructions: "Assign this user to a license"},
                    {action: "Delete", icon: "trash", instructions: "Delete a user from the organization (does not affect their access to other organizations)"}
                ]
            };

            pages[cm.ORGANIZATION_USERS.stateName] = {
                title: "Organization Users",
                type: "Users",
                instructions: 'Available Actions – Organization Users',
                actions: [
                    {action: "New User", icon: "plus", instructions: "Create a new User (sends an invitation email)"},
                    {action: "Details", icon: "info", instructions: "View additional datails about User Roles"},
                    {action: "Reset Password", icon: "lock", instructions: "Email user a link to reset their password"},
                    {action: "Activate", icon: "power-off", instructions: "Email user a link to activate their account"},
                    {action: "Delete User", icon: "trash", instructions: "Delete a user from the organization (does not affect their access to other organization)"}
                ]
            };

            pages[cm.ORGANIZATION_CONTACTS.stateName] = {
                title: "Organization Contacts",
                type: "Organization Contacts",
                instructions: 'Available Actions – Organization Contacts',
                actions: [
                    {action: "New Contact", icon: "plus", instructions: "Create a new contact record"},
                    {action: "Upload Contact Logo", icon: "picture-o", instructions: "Upload contact logo"},
                    {action: "Edit Contact", icon: "pencil", instructions: "Edit an existing contact record"},
                    {action: "Delete Contact", icon: "trash", instructions: "Delete an existing contact record (cannot be undone)"},
                    {action: "Info", icon: "info", instructions: "View all fields of an existing contact record"}
                ]
            };

            pages[cm.ORGANIZATION_TOOLS_TYPE_TOOLLIBRARIES.stateName] = {
                title: "Organization Tools",
                type: "Org Tool Libraries",
                instructions: 'Available Actions – Org Tool Libraries',
                actions: [
                    {icon: "plus", instructions: "Create a new Tool Library"},
                    {icon: "pencil", instructions: "Edit a Tool Library"},
                    {icon: "files-o", instructions: "Create a copy of an existing Tool Library"},
                    {icon: "info", instructions: "View additional information/Details"},
                    {icon: "ban", instructions: "Disable an existing Tool Library"},
                    {icon: "undo", instructions: "Re-load a disabled Tool Library"},
                    {icon: "trash", instructions: "Delete a Tool Library from the organization"}
                ]
            };

            pages[cm.ORGANIZATION_TOOLS_TYPE_PRESETS.stateName] = {
                title: "Organization Tool Presets",
                type: "Org Tool Presets",
                instructions: 'Available Actions – Org Tool Presets',
                actions: [
                    {icon: "plus", instructions: "Create a new Preset"},
                    {icon: "pencil", instructions: "Edit a Preset"},
                    {icon: "files-o", instructions: "Create a copy of an existing Preset"},
                    {icon: "info", instructions: "View additional information/Details"},
                    {icon: "ban", instructions: "Disable an existing Preset"},
                    {icon: "undo", instructions: "Re-load a disabled Preset"},
                    {icon: "trash", instructions: "Delete a Preset from the organization"}
                ]
            };

            pages[cm.ORGANIZATION_TESTSETUPS.stateName] = {
                title: "Test Setups",
                type: "Test Setups",
                instructions: 'Available Actions – Test Setups',
                actions: [
                    {icon: "plus", instructions: "Create a new Test Setup"},
                    {icon: "files-o", instructions: "Create a copy of an existing Test Setup"},
                    {icon: "ban", instructions: "Disable an existing Test Setup"},
                    {icon: "undo", instructions: "Re-load a disabled Test Setup"}
                ]
            };

            pages[cm.ORGANIZATION_TESTLIMITS.stateName] = {
                title: "Test Limits",
                type: "Test Limits",
                instructions: 'Available Actions – Test Limits',
                actions: [
                    {icon: "plus", instructions: "Create a new Test Limit"},
                    {icon: "files-o", instructions: "Create a copy of an existing Test Limit"},
                    {icon: "ban", instructions: "Disable an existing Test limit"},
                    {icon: "undo", instructions: "Re-load a disabled Test Limit"}
                ]
            };

            pages[cm.PROJECTS_PROJECT_TRACEVIEWER.stateName] = {
                title: "Trace Viewer",
                type: "Trace Viewer",
                instructions: "Trace Viewer Navigation Help",
                actions: [
                    {
                        icon: "", instructions: "<b>Trace View Mode Select</b>",
                        subtopics: [{
                            text: 'Click on <b>A-B Cursor / Event Data</b> slider to change trace view mode'
                        }, {
                            text: '<b>A-B Cursor</b> selected: Displays A and B locations, loss from start at each tested wavelength, A-to-B length and loss, and A-to-B loss/distance.'
                        }, {
                            text: '<b>Event Data</b> selected: Displays loss and reflectance of currently selected event and cumulative loss from start to selected event (including loss of event).'
                        }]
                    },
                    {
                        icon: "", instructions: "<b>Cursor Positioning</b>",
                        subtopics: [
                            {
                                text: 'Click on or touch A or B cursor to make it the active cursor; Active cursor is identified by a shaded flag with left & right arrows.',
                                subtopics: [{
                                    text: 'Click or touch left/right arrows to jump to previous (left) or next (right) event.'
                                }, {
                                    text: 'Location of cursor is displayed below each cursor flag.'
                                }, {
                                    text: 'Backscatter level for each wavelength is displayed above each cursor flag.'
                                }]
                            }, {
                                text: 'Click and drag or touch and drag A or B cursor to move it to desired location.'
                            }, {
                                text: 'Keyboard left/right keys jump currently active cursor to previous (left) or next (right) event.'
                            }, {
                                text: 'When Event Data mode is selected:',
                                subtopics: [{
                                    text: 'Touch <i class="fa fa-step-backward"></i> to move active cursor to start of fiber.'
                                }, {
                                    text: 'Touch <i class="fa fa-caret-left fa-lg"></i> to move active cursor to previous event.'
                                }, {
                                    text: 'Touch <i class="fa fa-caret-right fa-lg"></i> to move active cursor to next event.'
                                }, {
                                    text: 'Touch <i class="fa fa-step-forward"></i>  to move active cursor to last event.'
                                }]
                            }, {
                                text: '<b>Note</b>:  Cursor A cannot move beyond cursor B; once cursor A catches up with cursor B, moving cursor A further right will also move cursor B further right.  Similarly, cursor B cannot move before cursor A; once cursor B pushes up against cursor A, moving cursor B further left will also move cursor A further left.'
                            }
                        ]},
                    {
                        icon: "", instructions: "<b>Zoom Control</b>",
                        subtopics: [{
                            text: '<i class="fa fa-search-plus"></i> to zoom in one level centered about currently active cursor'
                        }, {
                            text: '<i class="fa fa-search-minus"></i> to zoom out one level centered about currently active cursor location'
                        }, {
                            text: '<i class="fa fa-search"></i> to enable mouse-controlled trace zoom functions'
                        }, {
                            text: '<i class="fa fa-arrows"></i> to enable mouse-controlled trace pan movement functions'
                        }, {
                            text: '<i class="fa fa-arrows-alt"></i> to zoom out fully and view the entire trace'
                        }, {
                            text: 'When mouse-controlled zoom is enabled (<i class="fa fa-search"></i> highlighted in blue): Using a mouse, click and drag box around window you wish to zoom into; Release to zoom into selected window.'
                        }, {
                            text: 'When mouse-controlled pan is enabled (<i class="fa fa-arrows"></i> highlighted in blue): Click or touch and drag trace to pan to a different location.'
                        }]
                    },
                    {
                        icon: "", instructions: "<b>View LinkMap or Return to Fiber Group Results Summary</b>",
                        subtopics: [{
                            text: 'Touch <b><i class="fa fa-table"></i> LinkMap</b> to view LinkMap and associated event table for current trace.'
                        }, {
                            text: 'Touch <b>Back to Results</b> to return to Fiber Group summary'
                        }]
                    }
                ]

            };

        })(pages, routesConstant.CUSTOMER);

        return pages;
    }
})();

